import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import {
    fetchBypassLink,
    FetchBypassLinkParams,
    fetchList,
    fetchLongLived,
    fetchSupportedSites,
    fetchUserscriptInfo,
} from '@/features/api/query'
import {
    ApiBypassResponse,
    APIList,
    ApiLonglivedResponse,
    ApiSupportedSitesResponse,
    UserscriptInfo,
} from '@/features/api/types'

export enum SITE_HOST {
    MAIN = 'main',
    MIRROR = 'mirror',
    DEV = 'dev',
}

const BACKEND_BASE_URL = {
    [SITE_HOST.MAIN]: 'https://api2.bypass.city',
    [SITE_HOST.MIRROR]: 'https://api2.adbypass.org',
    [SITE_HOST.DEV]: process.env.NEXT_PUBLIC_ENV_DEV_BACKEND || 'https://api2.bypass.city',
}

type TApiContext = {
    fetchSupportedSites: () => Promise<ApiSupportedSitesResponse[]>
    fetchBypassLink: (params: FetchBypassLinkParams) => Promise<ApiBypassResponse>
    fetchLongLived: (id: string) => Promise<ApiLonglivedResponse>
    fetchList: () => Promise<APIList[]>
    userScriptInfo: () => Promise<UserscriptInfo>
    apiBaseUrl: string
    host: SITE_HOST
}

const ApiContext = createContext<TApiContext>({} as TApiContext)

export const UseApi = ({ children }: PropsWithChildren<{}>) => {
    const [apiBaseUrl, setApiBaseUrl] = useState(BACKEND_BASE_URL[SITE_HOST.MAIN])
    const [host, setHost] = useState(SITE_HOST.MAIN)

    useEffect(() => {
        if (window.location.hostname === 'adbypass.org') {
            setHost(SITE_HOST.MIRROR)
            setApiBaseUrl(BACKEND_BASE_URL[SITE_HOST.MIRROR])
        } else if (window.location.hostname === 'localhost') {
            setHost(SITE_HOST.DEV)
            setApiBaseUrl(BACKEND_BASE_URL[SITE_HOST.DEV])
        }
    })

    return (
        <ApiContext.Provider
            value={{
                apiBaseUrl,
                host,
                fetchSupportedSites: () => fetchSupportedSites(apiBaseUrl),
                fetchBypassLink: (params) => fetchBypassLink(apiBaseUrl, params),
                fetchLongLived: (id) => fetchLongLived(apiBaseUrl, id),
                fetchList: () => fetchList(apiBaseUrl),
                userScriptInfo: () => fetchUserscriptInfo(apiBaseUrl),
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}

export const useApi = () => {
    const context = useContext(ApiContext)
    if (context === undefined) {
        throw new Error('useApi must be used within a ApiProvider')
    }
    return context
}
