import { NavigationProgress } from '@mantine/nprogress'
import { MDXProvider } from '@mdx-js/react'
import { PropsWithChildren } from 'react'

import { DbProvider } from '@/provider/useClientDB'

import { AdsProvider } from './useAds'
import { UseApi } from './useApi'
import { PromptingProvider } from './useBeforeInstallPrompt'
import { DetectUserscriptProvider } from './useDetectUserscript'

export const ProviderWrapper = ({ children }: PropsWithChildren) => {
    return (
        <MDXProvider>
            <NavigationProgress />
            <PromptingProvider>
                <DetectUserscriptProvider>
                    <AdsProvider>
                        <UseApi>
                            <DbProvider>{children}</DbProvider>
                        </UseApi>
                    </AdsProvider>
                </DetectUserscriptProvider>
            </PromptingProvider>
        </MDXProvider>
    )
}
