import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import InstallPrompt from '@/components/pwa/InstallPrompt'
import { storageKeys } from '@/components/stats/sorageKeys'
import { BeforeInstallPromptEvent } from '@/types/pwa'
import { anEvent } from '@/utils/anEvents'
import { setTimedKey, timedKeyIsOlderThan } from '@/utils/track/timedKey'

type InstallPromptState = {
    event: BeforeInstallPromptEvent | undefined
    isPrompting: boolean
    isAvailable: boolean
    showInfoModal: boolean
    isInstalled: boolean
    isInPwa?: boolean
}

const initialInstallPromptState: InstallPromptState = {
    showInfoModal: false,
    event: undefined,
    isAvailable: false,
    isPrompting: false,
    isInstalled: false,
    isInPwa: false,
}

type PromptingContextType = InstallPromptState & {
    prompt: () => Promise<void>
}

const PromptingContext = createContext<PromptingContextType>({
    ...initialInstallPromptState,
    prompt: async () => {},
})

export const PromptingProvider = ({ children }: PropsWithChildren<{}>) => {
    const [installPromptState, setInstallPromptState] =
        useState<InstallPromptState>(initialInstallPromptState)

    const prompt = async () => {
        setInstallPromptState({ ...getInitialState(), showInfoModal: true })
        anEvent('show_install_modal', {
            category: 'pwa',
            label: 'show',
        })
    }

    const runInstallPrompt = async () => {
        if (installPromptState.event) {
            setInstallPromptState({ ...getInitialState(), isPrompting: true })
            const { outcome } = await installPromptState.event.prompt()
            setInstallPromptState({ ...getInitialState(), isPrompting: false })
            if (outcome === 'accepted') {
                localStorage.setItem(storageKeys.pwaInstall, 'true')
                anEvent('pwa_install_accepted', {
                    category: 'pwa',
                    label: 'install_accepted',
                })
            } else {
                anEvent('pwa_install_rejected', {
                    category: 'pwa',
                    label: 'install_rejected',
                })
            }
            return outcome === 'accepted'
        }
        return false
    }

    const getInitialState = (): InstallPromptState => {
        const isInPwa = matchMedia('(display-mode: standalone)').matches

        if (isInPwa) {
            anEvent('is_in_pwa', {
                category: 'pwa',
                label: 'from_pwa',
            })
            localStorage.setItem(storageKeys.pwaInstall, 'true')
        }
        const localStoragePwaInstall = localStorage.getItem(storageKeys.pwaInstall) === 'true'

        if (!window.beforeinstallpromptEvent) localStorage.removeItem(storageKeys.pwaInstall)

        return {
            ...initialInstallPromptState,
            event: window.beforeinstallpromptEvent,
            isAvailable: !isInPwa && !!window.beforeinstallpromptEvent,
            isInstalled: localStoragePwaInstall && !isInPwa,
            isInPwa,
        }
    }

    const initFn = async () => {
        const initState = getInitialState()
        setInstallPromptState(initState)
        if (
            !timedKeyIsOlderThan({ time: { month: 1 }, key: storageKeys.menuTip }) &&
            timedKeyIsOlderThan({ time: { month: 1 }, key: storageKeys.showIncentivesInstall }) &&
            !initState.isInPwa &&
            !initState.isInstalled &&
            initState.isAvailable
        ) {
            prompt()
        }
    }

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            window.beforeinstallpromptEvent = e
            initFn()
        })
        initFn()
    }, [])

    return (
        <PromptingContext.Provider value={{ ...installPromptState, prompt }}>
            <InstallPrompt
                open={installPromptState.showInfoModal}
                promptInstall={runInstallPrompt}
                isPrompting={installPromptState.isPrompting}
                onClose={() => {
                    setTimedKey(storageKeys.showIncentivesInstall)
                    setInstallPromptState({
                        ...getInitialState(),
                        showInfoModal: false,
                    })
                }}
            />
            {children}
        </PromptingContext.Provider>
    )
}

const useBeforeInstallPrompt = () => {
    return useContext(PromptingContext)
}

export default useBeforeInstallPrompt
