export const storageKeys = {
    modalShown: 'track-donateModal',
    successfulResolves: 'track-successfulResolves',
    antiAdBlocker: 'track-antiAdBlocker',
    menuTip: 'track-menuTip',
    cookieDialog: 'track-cookieDialog',
    showSurvey: 'track-showSurvey',
    failedResolves: 'track-failedResolves',
    showIncentivesInstall: 'track-showIncentivesInstall',
    pwaInstall: 'track-pwaInstall',
    showPopUpAd: 'track-showPopUpAd',
    showAdsInfo: 'track-showAdsInfo',
    trackReEnableAds: 'track-rReEnableAds',
    countAdsDisplayed: 'track-countAdsDisplayed',
    lhProjectCampaign: 'track-lhProjectCampaign',
    disableUpdateModal: 'track-disableUpdateModal',
    showRobloxExec: 'track-showRobloxExec',
    resolveToMega: 'track-resolveToMega',
}
