import { clarity } from '@glassfm/react-clarity'
import { useLocalStorage } from '@mantine/hooks'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { anEvent } from '@/utils/anEvents'
import { compareVersion } from '@/utils/parseVersion'

interface ScriptInfo {
    version: string
    downloadURL?: string
}

interface ScriptConfiguration {
    thirdParty?: boolean
    brandingName?: string
    accentColor?: string
    infoUrl?: string
    disableSmartFeatures?: boolean
}

declare global {
    interface Window {
        scriptInfo?: ScriptInfo
    }
}

type DetectUserscriptProviderState = {
    version: string
    outdated: boolean
    detected: boolean
    outdatedLevel: number
    downloadURL?: string
    disableSmartFeatures: boolean
    toggleSmartFeatures: () => void
}

const initialDetectUserscriptProviderState: DetectUserscriptProviderState = {
    detected: false,
    outdated: false,
    downloadURL: '',
    outdatedLevel: 0,
    disableSmartFeatures: false,
    toggleSmartFeatures: () => {},
    version: '',
}

const DetectUserscriptContext = createContext<DetectUserscriptProviderState>(
    initialDetectUserscriptProviderState
)

export const DetectUserscriptProvider = ({ children }: PropsWithChildren<{}>) => {
    const [detected, setDetected] = useState<boolean>(false)
    const [outdated, setOutdated] = useState<boolean>(false)
    const [downloadURL, setDownloadURL] = useState<string>('unknown')
    const [version, setVersion] = useState<string>('')
    const [outdatedLevel, setOutdatedLevel] = useState<number>(0)

    const [disableSmartFeatures, setSmartFeatures] = useLocalStorage<boolean>({
        key: 'smart-features-userscript',
        defaultValue: false,
    })

    const initScriptInfo = (detail: ScriptInfo) => {
        setDetected(true)
        setDownloadURL(detail.downloadURL || 'unknown')
        setVersion(detail.version)
        const outdatedLevel = compareVersion(detail.version)
        setOutdated(outdatedLevel >= 2)
        setOutdatedLevel(outdatedLevel)

        anEvent('userscript_detected', {
            category: 'userscript',
            version: detail.version,
            downloadURL: detail.downloadURL,
        })

        if (clarity.hasStarted()) {
            clarity.setTag('userscript', detail.version)
            if (detail.downloadURL) {
                clarity.setTag('userscript_download', detail.downloadURL)
            }
        }
    }

    const detectUserscript = () => {
        if (window.scriptInfo) {
            initScriptInfo(window.scriptInfo)
        }
    }

    const hookListenFunction = () => {
        window.addEventListener('userScriptInfo', async (event) => {
            const { detail } = event as unknown as { detail: ScriptInfo }
            initScriptInfo(detail)
        })
    }

    useEffect(() => {
        hookListenFunction()
        detectUserscript()
    }, [])

    return (
        <DetectUserscriptContext.Provider
            value={{
                detected,
                outdated,
                downloadURL,
                version,
                outdatedLevel,
                disableSmartFeatures,
                toggleSmartFeatures: () => setSmartFeatures(!disableSmartFeatures),
            }}
        >
            {children}
        </DetectUserscriptContext.Provider>
    )
}

export const useDetectUserscript = () => useContext(DetectUserscriptContext)
