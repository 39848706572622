const userscriptData = require('../../public/.well-known/ping.json')

export const parseString = (version: string) => {
    const [major, minor, patch] = version.split('.')
    return {
        major,
        minor,
        patch,
    }
}

/**
 * Compare the installed version with the latest version
 * @param {string} installed_version
 * @param {string} check_against_version
 * @returns {0 | 1 | 2 | 3} 0: up to date, 1: patch update, 2: minor update, 3: major update
 **/

export const compareVersion = (
    installed_version: string,
    check_against_version?: string
): 0 | 1 | 2 | 3 => {
    const v1 = parseString(check_against_version ?? userscriptData.version)
    const v2 = parseString(installed_version)

    if (v1.major > v2.major) {
        return 3
    } else if (v1.minor > v2.minor) {
        return 2
    } else if (v1.patch > v2.patch) {
        return 1
    } else {
        return 0
    }
}
