const manifest = require('../public/manifest.json')

const config = {
    title: manifest.name,
    description: manifest.description,
    // baseUrl: 'http://localhost:3000',
    discordInviteLink: 'https://discord.gg/bypass-city',
    greaseForkLink: 'https://bypass.city/how-to-install-userscript',

    GA_TRACKING_ID: 'G-NB9H6YS2J2',
    GTM_ID: 'GTM-MJ6Q8BKX',
    donate_link: '/donate',
    CLARITY_ID: 'm0p1wwmvbx',

    // google_page_ads_client: 'ca-pub-3979880217016057',

    survey_link: 'https://forms.bypass.city/s/clyg55ma60001r0qmyoacfxag',

    ms_wait_time: 300,
    ad_height: '270px',
    ms_wait_time_on_redirect: 20,

    website: 'https://bypass.city/',

    website_url_name: 'bypass.city',
    website_short_name: manifest.short_name,
    example_link: 'https://linkvertise.com/329510/test-it-out/1',
    color: manifest.theme_color,
    favicon: {
        size: manifest.icons[0].sizes,
        type: manifest.icons[0].type,
        src: manifest.icons[0].src,
    },
    keywords: manifest.keywords.join(', '),
    cloudFlareTurnstileSiteKey: '0x4AAAAAAAGzw6rXeQWJ_y2P',
    reCaptchaSiteKey: '6LeNVz4pAAAAANtPbwkx703l9eOt22B8Y7m-N5Cx',
    adDirectLink: 'https://a.bypass.city/jump/next.php?r=7667110',
    userScriptDirectLink:
        'https://api2.adbypass.org/userscript/download/bypass.user.js',
}

export default config
