import {
    Button,
    CloseButton,
    Divider,
    Flex,
    Group,
    Image,
    List,
    Loader,
    Text,
    ThemeIcon,
    Title,
} from '@mantine/core'
import { IconApps, IconBrandChrome, IconShare, IconWindowMaximize } from '@tabler/icons-react'
import React from 'react'

import InfoModalFrame from '@/components/stats/InfoModalFrame'

interface InstallPromptProps {
    open: boolean
    onClose: () => void
    promptInstall: () => void
    isPrompting: boolean
}

const InstallPrompt = ({ open, onClose, promptInstall, isPrompting }: InstallPromptProps) => {
    const onInstall = async () => {
        promptInstall()
        onClose()
    }

    return (
        <InfoModalFrame
            open={open}
            size='lg'
            onClose={onClose}
            topIcon={
                <ThemeIcon
                    radius='xl'
                    size='3rem'
                    variant='gradient'
                    gradient={{ from: 'teal', to: 'blue' }}
                >
                    <IconApps size='1.5rem' />
                </ThemeIcon>
            }
        >
            <Text ta='center'>
                <Title order={3} mb='sm'>
                    Install bypass.city as an Web-App
                </Title>
            </Text>

            <Divider variant='solid' my='md' />

            <Text mb='xs'>
                Install Bypass.city as an Webapp on your device to make the experience even better and
                get extra features.
            </Text>
            <Text fs='italic' mb='sm'>
                This won{"'"}t be a real application, but it will be a standalone window that you can use
                to bypass links.
            </Text>

            <List spacing='xs' size='lg'>
                <List.Item
                    icon={
                        <ThemeIcon color='teal' size='md' radius='xl'>
                            <IconWindowMaximize size='1rem' />
                        </ThemeIcon>
                    }
                >
                    Standalone Window
                </List.Item>
                <List.Item
                    icon={
                        <ThemeIcon color='teal' size='md' radius='xl'>
                            <IconBrandChrome size='1rem' />
                        </ThemeIcon>
                    }
                >
                    Go back to Browser after bypass
                </List.Item>
                <List.Item
                    icon={
                        <ThemeIcon color='teal' size='md' radius='xl'>
                            <IconShare size='1rem' />
                        </ThemeIcon>
                    }
                >
                    Quick-Share to bypass.city from browser or application
                </List.Item>
            </List>

            <Flex justify='center' mt='lg' mb='lg' align='center'>
                <Image
                    mx='auto'
                    radius='md'
                    sx={(theme, u) => ({
                        maxWidth: '400px',
                        [u.smallerThan(theme.breakpoints.sm)]: {
                            maxWidth: '300px',
                        },
                    })}
                    src='images/screenshots/mobile-show-share-cut.png'
                    alt='Mobile Share'
                />
            </Flex>

            <Group mt='lg' justify='space-between'>
                <Button
                    size='md'
                    disabled={isPrompting}
                    leftSection={
                        isPrompting ? <Loader size='1rem' /> : <IconApps size='1rem' stroke={1.5} />
                    }
                    variant='gradient'
                    gradient={{ from: 'green', to: 'blue' }}
                    onClick={onInstall}
                >
                    Install
                </Button>
                <CloseButton title='Close popover' size='xl' onClick={onClose} iconSize={20} />
            </Group>
        </InfoModalFrame>
    )
}

export default InstallPrompt
