import { nprogress } from '@mantine/nprogress'

import {
    ApiBypassResponse,
    APIList,
    ApiLonglivedResponse,
    ApiSupportedSitesResponse,
    CaptchaProvider,
    UserscriptInfo,
} from '@/features/api/types'

export const fetchSupportedSites = async (base: string) => {
    const res = await fetch(`${base}/available`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return (await res.json()) as ApiSupportedSitesResponse[]
}

export interface FetchBypassLinkParams {
    link: string
    token: string
    provider: CaptchaProvider
}

export const fetchBypassLink = async (
    base: string,
    { link, token, provider }: FetchBypassLinkParams
) => {
    nprogress.start()
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    if (process.env.NEXT_PUBLIC_ENV_DEV_MODE && process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER) {
        headers.append('token', process.env.NEXT_PUBLIC_ENV_BYPASS_CAPTCHA_HEADER)
    } else headers.append('token', token)

    headers.append('x-captcha-provider', provider)

    const init: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({
            url: link,
        }),
    }

    const res = await fetch(`${base}/bypass`, init)

    nprogress.reset()
    const json = (await res.json()) as ApiBypassResponse
    if (json.message) {
        throw new Error(json.message)
    }

    return json
}

export const fetchUserscriptInfo = async (base: string) => {
    const res = await fetch(`${base}/userscript/info`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return (await res.json()) as UserscriptInfo
}

export const fetchLongLived = async (base: string, id: string) => {
    const res = await fetch(`${base}/long-lived/${id}/status`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const json = (await res.json()) as ApiLonglivedResponse

    if (json.errorMessage) {
        throw new Error(json.errorMessage)
    }

    return json
}

export const fetchList = async (base: string) => {
    const res = await fetch(`${base}/list`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return (await res.json()) as APIList[]
}
