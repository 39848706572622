import { useLocalStorage, useSessionStorage } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { storageKeys } from '@/components/stats/sorageKeys'
import config from '@/config'
import { anEvent } from '@/utils/anEvents'
import { sleep } from '@/utils/sleep'
import { setTimedKey, timedKeyIsOlderThan } from '@/utils/track/timedKey'

type AdsProviderState = {
    isEnable: boolean
    toggleAds: () => void
    testAdBlocker: () => void
    adBlockerDetected: boolean
    adsHidden?: boolean
}

const initialAdsProviderState: AdsProviderState = {} as AdsProviderState

const testUrl = async (url: string): Promise<boolean> => {
    try {
        const response = await fetch(url)
        if (response.status === 200 || response.status === 201) {
            return true
        } else {
            return false
        }
    } catch (error) {
        console.log(url, error)
        return false
    }
}

const AdsContext = createContext<AdsProviderState>(initialAdsProviderState)

const fetchGtm = async () => {
    const gtmUrl = `https://www.googletagmanager.com/gtm.js?id=${config.GTM_ID}`
    try {
        await testUrl(gtmUrl)
        return true
    } catch (error) {
        console.log('fetchGtm', error)
        return false
    }
}

export const AdsProvider = ({ children }: PropsWithChildren<{}>) => {
    const [adsEnabled, setAdsProviderState] = useLocalStorage<boolean>({
        key: 'ads_enabled',
        defaultValue: true,
    })
    const [adsHidden, setAdsHidden] = useLocalStorage<boolean>({
        key: 'ads_hidden',
        defaultValue: false,
    })

    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [adBlockerDetected, setAdBlockerDetected] = useState<boolean>(false)
    const [sessionAdBlockerDetected, setSessionAdBlockerDetected] = useSessionStorage({
        key: 'adBlockerDetected',
        defaultValue: true,
    })

    const { query, isReady } = useRouter()

    const testAdBlocker = async () => {
        const result = await new Promise(async (resolve) => {
            for (let i = 0; i < 3; i++) {
                if (await fetchGtm()) {
                    resolve(true)
                    break
                }
                await sleep(1000)
            }
            resolve(false)
        })
        setSessionAdBlockerDetected(!result)
        setAdBlockerDetected(!result)
    }

    useEffect(() => {
        if (isReady) {
            if (query.ref === 'v') {
                setAdsProviderState(false)
                setAdsHidden(true)
            }
        }
    }, [isReady])

    useEffect(() => {
        if (isLoaded && sessionAdBlockerDetected) {
            testAdBlocker()
        }
    }, [isLoaded])

    useEffect(() => {
        setIsLoaded(true)
        if (timedKeyIsOlderThan({ time: { days: 3 }, key: storageKeys.trackReEnableAds })) {
            setAdsProviderState(true)
            setTimedKey(storageKeys.trackReEnableAds)
        }
    })

    //
    // useEffect(() => {
    //     if (isLoaded && adsEnabled) {
    //         require('@/components/ads/AdsScript')
    //     }
    // }, [isLoaded, adsEnabled])

    const toggleAds = () => {
        if (!adsEnabled) {
            anEvent('enable_ads', {
                category: 'toggle_ads',
                label: 'enable',
            })
        } else {
            anEvent('disable_ads', {
                category: 'toggle_ads',
                label: 'disable',
            })
        }
        setAdsProviderState(!adsEnabled)
    }

    return (
        <AdsContext.Provider
            value={{
                isEnable: adsEnabled,
                toggleAds,
                testAdBlocker: testAdBlocker,
                adBlockerDetected: adBlockerDetected,
                adsHidden,
            }}
        >
            {/*{adsConfig.services.AdMaven &&*/}
            {/*    isLoaded &&*/}
            {/*    adsEnabled &&*/}
            {/*    !process.env.NEXT_PUBLIC_ENV_DEV_MODE && (*/}
            {/*        <Script*/}
            {/*            id='script'*/}
            {/*            src={*/}
            {/*                host === SITE_HOST.MAIN*/}
            {/*                    ? '//d2bs5vtcw2lxsv.cloudfront.net/?tvsbd=1043890'*/}
            {/*                    : '//d2bs5vtcw2lxsv.cloudfront.net/?tvsbd=1043911'*/}
            {/*            }*/}
            {/*            data-cfasync='false'*/}
            {/*        />*/}
            {/*    )}*/}
            {children}
        </AdsContext.Provider>
    )
}

export const useAds = () => {
    const context = useContext(AdsContext)

    return context
}
