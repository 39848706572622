import { Flex, MantineSize, Modal } from '@mantine/core'
import React from 'react'

interface IInfoModalFrame {
    topIcon: JSX.Element
    open: boolean
    size?: MantineSize
    onClose: () => void
}

function InfoModalFrame({
    topIcon,
    onClose,
    open,
    children,
    size = 'md',
}: React.PropsWithChildren<IInfoModalFrame>) {
    return (
        <Modal
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={false}
            shadow='lg'
            opened={open}
            onClose={onClose}
            size={size}
            withCloseButton={true}
            centered
        >
            <Flex gap='md' mb='md' justify='center' align='center' direction='column' wrap='wrap'>
                {topIcon}
            </Flex>
            {children}
        </Modal>
    )
}

export default InfoModalFrame
