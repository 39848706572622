import { MantineThemeOverride } from '@mantine/core'

export const theme: MantineThemeOverride = {
    colors: {
        discord: [
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
            '#5865F2',
        ],
    },
}
