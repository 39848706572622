const InstallHook = () => {
    return (
        <script
            id='hookBeforeInstallPrompt'
            dangerouslySetInnerHTML={{
                __html: `
            window.process = {env: {VERSION: "11.0.4"}}
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault()
                window.beforeinstallpromptEvent = e
            })`,
            }}
        />
    )
}

export default InstallHook
