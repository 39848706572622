import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { LocalDb } from '@/features/AdTrackDb'

type AdsProviderState = {
    dbLoaded: boolean
}

const AdsContext = createContext<AdsProviderState>({} as AdsProviderState)

export const DbProvider = ({ children }: PropsWithChildren<{}>) => {
    const [dbLoaded, setDbLoaded] = useState<boolean>(false)
    useEffect(() => {
        window.db = new LocalDb()
        setDbLoaded(true)
    }, [])

    return (
        <AdsContext.Provider
            value={{
                dbLoaded,
            }}
        >
            {children}
        </AdsContext.Provider>
    )
}

export const useClientDb = () => {
    const context = useContext(AdsContext)

    return context
}
