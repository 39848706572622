
import { clarity } from '@glassfm/react-clarity'
import { event } from 'nextjs-google-analytics'

type EventOptions = Record<string, any> & {
    category?: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    userId?: string;
};

export function anEvent(action: string, options?: EventOptions): void {
    event(action, options)
    if (typeof window !== 'undefined' && clarity.hasStarted()) {
        clarity.addEvent(action)

    }
}

export function initiClarity(clarityId: string): void {
    if (typeof window !== 'undefined') {
        clarity.init(clarityId)
        if (clarity.hasStarted()) {
            clarity.consent()
        }
    }
}
