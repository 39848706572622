import { DefaultSeoProps } from 'next-seo'

import config from '@/config'

export const SEOconfig: DefaultSeoProps = {
    openGraph: {
        type: 'object',
        locale: 'en_US',
        url: config.website,
        siteName: config.website_short_name,
        description: config.description,
        title: config.title,
        images: [
            {
                url: 'images/og-image.png',
                width: 521,
                height: 268,
                alt: config.title,
                type: 'image/png',
            },
        ],
    },
    twitter: {
        cardType: 'summary_large_image',
    },
    canonical: config.website,
    title: config.title,
    description: config.description,
    defaultTitle: config.title,
    additionalMetaTags: [
        {
            name: 'twitter:title',
            content: config.title,
        },
        {
            name: 'twitter:description',
            content: config.description,
        },
        {
            name: 'twitter:image:src',
            content: '/images/og-image.png',
        },
        {
            name: 'keywords',
            content: config.keywords,
        },
        {
            name: 'application-name',
            content: config.website_short_name,
        },
        {
            name: 'apple-mobile-web-store-capable',
            content: 'yes',
        },
        {
            name: 'apple-mobile-web-store-status-bar-style',
            content: 'default',
        },
        {
            name: 'apple-mobile-web-store-title',
            content: config.website_short_name,
        },
        {
            name: 'format-detection',
            content: 'telephone=no',
        },
        {
            name: 'mobile-web-store-capable',
            content: 'yes',
        },
        {
            name: 'msapplication-TileColor',
            content: config.color,
        },
        {
            name: 'msapplication-tap-highlight',
            content: 'no',
        },
        {
            name: 'theme-color',
            content: config.color,
        },
    ],
}

export default SEOconfig
