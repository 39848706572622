import { Dexie, Table } from 'dexie'
import logger from 'dexie-logger'
import { DateTime } from 'luxon'

interface AdsTrackingDoc {
    id?: number
    campaignId: string
    elementId: string
    eventStart: Date
    eventEnd?: Date
}
declare global {
    interface Window {
        db: LocalDb
    }
}

export class LocalDb extends Dexie {
    adsTracking!: Table<AdsTrackingDoc>

    constructor() {
        super('localDb')
        this.version(1).stores({
            adsTracking: '++id, campaignId, elementId',
        })

        if (process.env.NEXT_PUBLIC_ENV_DEV_MODE) {
            this.use(logger())
        }

        // delete records older than 1 day
        this.adsTracking
            .where('eventStart')
            .below(DateTime.now().minus({ day: 1 }).toJSDate())
            .delete()
    }
}
